import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import {
  BackgorundImageComponent,
  FormGridComponent,
  ImageGridComponent,
  TextGridComponent,
} from '../components/campaign';
import { BlackLogoBand } from '../components/logoBands';
import SEO from '../components/seo';

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '80px',
    marginBottom: '40px',
    '& h3': {
      marginTop: 0,
      maxWidth: 600,
      marginBottom: '1rem',
    },
  },
  logoBandPosition: {
    marginBottom: '-95px',
  },
}));

const CampaignTemplate = ({ data }) => {
  const classes = useStyles();
  const myRef = useRef(null);
  const handleMoveToContact = () => {
    myRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const {
    title,
    heroImage,
    heroSectionTextDescription,
    textBlock1,
    imageBlock1,
    textBlock2,
    imageBlock2,
    textBlock3,
    imageBlock3,
    formId,
    formPortalId,
    contactFormHeaderText,
    finalTextBlock,
  } = data.allContentfulCampaignPage.edges[0].node;

  return (
    <>
      <SEO title={title} ogimage={heroImage.fluid.src} />
      <BackgorundImageComponent
        image={heroImage.fluid}
        title={title}
        subtitle={heroSectionTextDescription.childMarkdownRemark.html}
      />
      <Grid container className={classes.gridContainer}>
        <TextGridComponent
          button
          handleMoveToContact={handleMoveToContact}
          text={textBlock1.json}
        />
        {imageBlock1 && <ImageGridComponent fluid={imageBlock1.fluid} />}
      </Grid>
      <Grid container className={classes.gridContainer}>
        {imageBlock2 && (
          <ImageGridComponent imageLeft fluid={imageBlock2.fluid} />
        )}
        {textBlock2 && <TextGridComponent text={textBlock2.json} textCenter />}
      </Grid>
      <Grid container className={classes.gridContainer}>
        {textBlock3 && <TextGridComponent text={textBlock3.json} />}
        {imageBlock3 && <ImageGridComponent fluid={imageBlock3.fluid} />}
      </Grid>
      <Grid container className={classes.gridContainer} ref={myRef}>
        {contactFormHeaderText && (
          <FormGridComponent
            formId={formId}
            formPortalId={formPortalId}
            formDescription={contactFormHeaderText.json}
          />
        )}
        {finalTextBlock && <TextGridComponent text={finalTextBlock.json} />}
      </Grid>
      <div className={classes.logoBandPosition}>
        <BlackLogoBand />
      </div>
    </>
  );
};

export default CampaignTemplate;

export const pageQuery = graphql`
  query CampaignBySlug($slug: String!) {
    allContentfulCampaignPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          heroImage {
            fluid(
              quality: 90
              maxWidth: 1921
              maxHeight: 1002
              resizingBehavior: PAD
              background: "transparent"
            ) {
              ...GatsbyContentfulFluid
            }
          }
          heroSectionTitle
          heroSectionTextDescription {
            childMarkdownRemark {
              html
            }
          }
          textBlock1 {
            json
          }
          imageBlock1 {
            fluid(quality: 90, maxWidth: 1383, maxHeight: 720) {
              ...GatsbyContentfulFluid
            }
          }
          textBlock2 {
            json
          }
          imageBlock2 {
            fluid(quality: 90, maxWidth: 1383, maxHeight: 720) {
              ...GatsbyContentfulFluid
            }
          }
          textBlock3 {
            json
          }
          imageBlock3 {
            fluid(quality: 90, maxWidth: 1383, maxHeight: 720) {
              ...GatsbyContentfulFluid
            }
          }
          formId
          formPortalId
          contactFormHeaderText {
            json
          }
          finalTextBlock {
            json
          }
        }
      }
    }
  }
`;
