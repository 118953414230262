import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Img from 'gatsby-image';
import { COLOR_CORAL } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  imageGrid: {
    padding: '20px 0px 40px 100px',
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 0px 20px 0px',
      borderBottom: 'none',
      width: '100%',
    },
    '& a': {
      color: COLOR_CORAL,
      textDecoration: 'none',
    },
  },
  imageGrid2: {
    flexGrow: 1,
    padding: '20px 100px 0px 0px',
    '@media (max-width:900px)': {
      padding: '20px 50px 0px 0px',
    },
    '@media (max-width:600px)': {
      padding: '20px 0px 20px 0px',
    },
  },
  image: {
    maxHeight: 390,
  },
}));

const ImageGridComponent = ({ fluid, alt, imageLeft }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      className={
        imageLeft ? [classes.imageGrid, classes.imageGrid2] : classes.imageGrid
      }
    >
      <Img fluid={fluid} alt={alt} className={classes.image} />
    </Grid>
  );
};

export default ImageGridComponent;
