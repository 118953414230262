import { makeStyles } from '@material-ui/core';
import React from 'react';
import Img from 'gatsby-image';
import Grid from '@material-ui/core/Grid';
import { graphql, useStaticQuery } from 'gatsby';

const BlackLogoBandStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#191919',
    padding: '1rem 30px 1rem 30px',
  },
  images: {
    minHeight: '50px',
    maxWidth: 130,
    objectFit: 'scale-down',
    margin: 'auto',
  },
}));

const query = graphql`
  query BlackLogoBandQuery {
    allFile(
      sort: { fields: name, order: ASC }
      filter: {
        name: {
          in: [
            "0_scanship-web2"
            "1_biogreen-web2"
            "2_spirajoule-web2"
            "3_safesteril-web2"
            "4_ozosteril-web2"
            "5_acodero-web2"
            "chevensen_color"
          ]
        }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 200) {
              src
            }
          }
        }
      }
    }
  }
`;

const BlackLogoBand = () => {
  const classes = BlackLogoBandStyles();
  const data = useStaticQuery(query);
  const logoBandData = data.allFile.edges;

  return (
    <>
      <Grid container spacing={1} className={classes.container}>
        {logoBandData &&
          logoBandData.map((logo, index) => (
            <Grid item xs={4} sm={2} key={index}>
              <Img
                fluid={logo.node.childImageSharp.fluid}
                alt="logo"
                className={classes.images}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default BlackLogoBand;
