import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import HubspotForm from 'react-hubspot-form';

const useStyles = makeStyles(theme => ({
  formGrid: {
    padding: '20px 100px 40px 100px',
    maxWidth: '50%',

    [theme.breakpoints.down('lg')]: {
      padding: '20px 80px 40px 80px',
    },

    [theme.breakpoints.down('md')]: {
      padding: '20px 50px 40px 50px',
      maxWidth: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px 20px 20px',
      borderBottom: 'none',
      maxWidth: '100%',
    },
    formWrapper: {
      paddingBottom: 40,
    },
  },
}));

const FormGridComponent = ({ formId, formPortalId, formDescription, ref }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} className={classes.formGrid}>
      {documentToReactComponents(formDescription)}
      <div className={classes.formWrapper} ref={ref}>
        {formId && formPortalId && (
          <HubspotForm
            portalId={formPortalId}
            formId={formId}
            onSubmit={() => console.log('Submit!')}
            onReady={form => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        )}
      </div>
    </Grid>
  );
};

export default FormGridComponent;
