import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { COLOR_CORAL } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textGrid: {
    padding: '20px 100px 40px 100px',
    '& p': {
      maxWidth: 600,
    },
    '& h3': {
      marginTop: 0,
      maxWidth: 600,
      marginBottom: '1rem',
    },
    '& a': {
      color: COLOR_CORAL,
      textDecoration: 'none',
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },

    [theme.breakpoints.down('lg')]: {
      padding: '20px 80px 40px 80px',
    },

    [theme.breakpoints.down('md')]: {
      padding: '20px 50px 40px 50px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px 20px 20px',
      borderBottom: 'none',
    },
  },
  textGrid2: {
    alignSelf: 'center',
  },
  textButton: {
    borderRadius: '0px',
    boxShadow: 'none',
    fontSize: '12px',
    textTransform: 'none',
    fontFamily: 'Arial',
    color: 'white',
    padding: '8px 20px 8px 20px',
    backgroundColor: COLOR_CORAL,
    '&:hover': {
      backgroundColor: COLOR_CORAL,
      boxShadow: 'none',
    },
    '@media (max-width:800px)': {
      marginTop: 20,
    },
  },
  imageGrid: {},
  formGrid: {
    padding: '20px 100px 40px 100px',
    maxWidth: '50%',

    [theme.breakpoints.down('lg')]: {
      padding: '20px 80px 40px 80px',
    },

    [theme.breakpoints.down('md')]: {
      padding: '20px 50px 40px 50px',
      maxWidth: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px 20px 20px',
      borderBottom: 'none',
      maxWidth: '100%',
    },
  },
  marginSpace: {
    marginTop: '4rem',
  },
  contactButton: {
    borderRadius: '0px',
    boxShadow: 'none',
    fontSize: '12px',
    textTransform: 'none',
    fontFamily: 'Arial',
    color: 'white',
    padding: '8px 20px 8px 20px',
    backgroundColor: COLOR_CORAL,
    '&:hover': {
      backgroundColor: COLOR_CORAL,
      boxShadow: 'none',
    },
    '@media (max-width:800px)': {
      marginTop: 20,
    },
  },
}));

const TextGridComponent = ({
  text,
  button,
  handleMoveToContact,
  textCenter,
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      className={
        textCenter ? [classes.textGrid, classes.textGrid2] : classes.textGrid
      }
    >
      {documentToReactComponents(text)}
      {/* <br /> */}
      {button && (
        <Button
          variant="contained"
          className={classes.contactButton}
          onClick={handleMoveToContact}
        >
          Contact us
        </Button>
      )}
    </Grid>
  );
};

export default TextGridComponent;
