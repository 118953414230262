import { makeStyles } from '@material-ui/core';
import React from 'react';
import Img from 'gatsby-image';

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: '100px',
    display: 'grid',
    'grid-template-columns': 'repeat(6, 1fr)',
    'grid-gap': '10px',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      'grid-template-columns': 'repeat(2, 1fr)',
    },
  },
  images: {
    maxWidth: '130px',
    minHeight: '65px',
  },
}));

const ColoredLogoBand = ({ logoBandData }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        {logoBandData &&
          logoBandData.map((logo, index) => (
            <Img
              key={index}
              fluid={logo.node.childImageSharp.fluid}
              alt="logo"
              className={classes.images}
            />
          ))}
      </div>
    </>
  );
};

export default ColoredLogoBand;
