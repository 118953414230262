import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import BackgroundImage from 'gatsby-background-image';

const backgroundUseStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-80px',
    flexGrow: '1',
  },
  heroImage: {
    height: '80vh',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: '85vh',
    },
  },
  headerCenterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerCenter: {
    fontSize: '5vw',
    color: 'white',
    textAlign: 'left',
    fontWeight: 'bold',
    lineHeight: '0.86em',
    textTransform: 'uppercase',
    marginBlockEnd: '0em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '4vh',
    },
  },
  subHeaderLeftInner: {
    color: 'white',
    maxWidth: '70%',
    textAlign: 'left',
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (min-width:2500px)': {
      fontSize: 22,
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: 40,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 40,
      fontSize: 14,
      maxWidth: '85%',
      lineHeight: '1em',
    },
  },
}));

const BackgorundImageComponent = ({ image, title, subtitle }) => {
  const classes = backgroundUseStyles();

  return (
    <div className={classes.container}>
      <BackgroundImage fluid={image} className={classes.heroImage}>
        <Grid
          container
          direction="column"
          justify="center"
          style={{ marginTop: '120px' }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '60%',
              }}
            >
              <h1 className={classes.headerCenter}>{title}</h1>
              <div>
                <div
                  className={classes.subHeaderLeftInner}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: subtitle,
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </BackgroundImage>
    </div>
  );
};

export default BackgorundImageComponent;
